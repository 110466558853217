import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { queryStringService } from "../../../Application/utils/queryStringService";
import { Routes } from "../../../common/routes";
import { PriorityLevel } from "../../../Discover/types";
import { HomePageContentItem } from "../../types";
import { CategoryItem } from "../CategoryItem/CategoryItem";
import styles from "./CategoriesStacks.module.scss";
import { EntityType } from "../../../common/types";
import { DiscoveryFilters } from "../../../Discover/config/filters";

const cx = classNames.bind(styles);

export interface Props {
  items: HomePageContentItem[];
  showBorder: boolean;
  priorityLevel: PriorityLevel;
}

export const CategoriesStacks: React.FC<Props> = ({ items, showBorder, priorityLevel }) => {
  const { t } = useTranslation(["application"]);

  const getPriorityFilter = (priority: PriorityLevel): DiscoveryFilters => {
    return { filters: { priority: [priority], entityType: [EntityType.Flow] } };
  };

  return (
    <div className={cx(styles.root, { [styles.noBorder]: !showBorder })}>
      {items && items.length > 0 ? (
        items.map(item => <CategoryItem item={item} key={item.id} completionStatus={item.completionStatus} />)
      ) : (
        <div>There are no assigned items with the current priority</div>
      )}
      <div className={styles.viewAll}>
        <Link
          to={`${Routes.Discover.exact}?${queryStringService.stringify(getPriorityFilter(priorityLevel))}`}
          className={styles.viewAllRedirectText}
          data-testid="view-all"
        >
          {t("common.viewAll")}
        </Link>
      </div>
    </div>
  );
};
