import { BackLink } from "../BackLink/BackLink";

import styles from "./BackTitle.module.scss";

export interface Props {
  to: string;
  state?: any;
  title: string;
}

export const BackTitle: React.FC<Props> = ({ to, title, state }) => {
  return (
    <div className={styles.root}>
      <BackLink to={to} state={state}></BackLink>
      <h1 className={styles.title}>{title}</h1>
    </div>
  );
};
