import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { doesSpanMultipleDays } from "./dateUtil";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat); // For formatting timezone (e.g. "PDT") in the date string

export const formatSessionDateTime = (startDateTime: string, endDateTime: string, tz = "UTC"): string => {
  const start = dayjs(startDateTime).tz(tz);
  const end = dayjs(endDateTime).tz(tz);

  return `${start.format("dddd")}, ${start.format("L")}
   ${start.format("LT")} - ${end.format("LT")}`;
};

export const formatSessionDateTimeToLocalTime = (
  startDateTimeUtc: string,
  endDateTimeUtc: string,
  timeZone: string,
  withFullDateFormat: boolean = false,
): string => {
  const start = dayjs.utc(startDateTimeUtc).tz(timeZone);
  const end = dayjs.utc(endDateTimeUtc).tz(timeZone);
  if (doesSpanMultipleDays(start, end)) {
    return withFullDateFormat
      ? // ie Wednesday, August 16, 2018 10:30AM - Thursday, August 16, 2018 9:00AM
        `${start.format("dddd, LL, LT")} - ${end.format("dddd, LL, LT")}`
      : // ie Wednesday, 01/01/2022 10:30AM - Thursday, 01/02/2022 9:00AM
        `${start.format("dddd, L LT")} - ${end.format("dddd, L LT")}`;
  }

  return withFullDateFormat
    ? `${start.format("dddd, LL")}, \n${start.format("LT")} - ${end.format("LT z")}`
    : `${start.format("dddd, L LT")} - ${end.format("LT z")}`;
};

export const isSessionExpiredInLocalTime = (endDateTimeUtc: string, timeZone: string): boolean => {
  const now = dayjs();
  const end = dayjs.utc(endDateTimeUtc).tz(timeZone);
  return now.isAfter(end);
};
