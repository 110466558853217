import cn from "classnames";
import classNames from "classnames/bind";
import React from "react";
import { Icon, Image } from "semantic-ui-react";
import styles from "./EventInfo.module.scss";

const cx = classNames.bind(styles);

interface Props {
  title: string;
  description?: string;
  thumbnailUrl?: string | null;
  className?: string;
}

export const EventInfo: React.FC<Props> = (props: Props) => {
  const { title, description, thumbnailUrl, className } = props;
  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.image}>
        {thumbnailUrl ? (
          <Image src={thumbnailUrl} rounded className={cx(styles.thumbnailPlaceholder, styles.image)} />
        ) : (
          <div className={cx(styles.thumbnailPlaceholder, styles.default)}>
            <Icon className={cx("calendar alternate outline", styles.defaultThumbnail)} color="grey" />
          </div>
        )}
      </div>
      <div className={styles.eventInfo}>
        <div className={styles.title} tabIndex={0}>
          {title}
        </div>
        <p className={styles.description} tabIndex={0}>
          {description}
        </p>
      </div>
    </div>
  );
};
