import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { PeriodType, RecurrenceSession } from "../types";
import { formatTicksToTime } from "./formatTicksToTime";
import { dayOfWeeks, RecurrenceDescriptionBuilder } from "./recurrenceDescriptionBuilder";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const getRecurrenceMetadata = (recurrenceSession: RecurrenceSession | null | undefined): string => {
  const {
    sessionStartDate,
    seriesEndDate,
    startsAtTicksMinutes,
    endsAtTicksMinutes,
    periodType,
    periodValue,
    periodicRule,
    weekDays,
    timeZone = "UTC",
  } = recurrenceSession!;

  return new RecurrenceDescriptionBuilder()
    .startingOn(sessionStartDate)
    .endingOn(seriesEndDate)
    .startingAt(formatTicksToTime(startsAtTicksMinutes))
    .endingAt(formatTicksToTime(endsAtTicksMinutes))
    .usingTimeZone(timeZone)
    .usingPeriod(periodType ?? PeriodType.Day)
    .usingPeriodicRule(periodicRule)
    .withRepetition(periodValue ?? 1)
    .onWeekDays(weekDays.map((x: number) => dayOfWeeks[x]))
    .build();
};
