import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import { ReactComponent as Calendar } from "../../../../assets/images/calendar-clock.svg";
import { ErrorType, HttpErrorView } from "../../../Application/layout/HttpErrorView/HttpErrorView";
import { EmptyState } from "../../../Application/components/EmptyState/EmptyState";
import { FixedHeightAppPageWrapper } from "../../../Application/layout";
import { BackTitle } from "../../../Application/layout/BackTitle/BackTitle";
import { FetchingLayout } from "../../../Application/layout/FetchingLayout/FetchingLayout";
import { isTicksBefore, ticksToMoment } from "../../../Application/utils/date";
import { queryStringService } from "../../../Application/utils/queryStringService";
import {
  appointmentSelector,
  errorSelector,
  eventSelector,
  hasAccessSelector,
  req,
  reset,
  statusSelector,
  track,
} from "../../redux/event/redirectSlice";
import { EventAppointment, GetEventAppointmentRequest } from "../../types";
import { REDIRECT_TIMEOUT } from "../../types/contstants";
import { EventInfo } from "../EventViewer/EventInfo/EventInfo";
import { formatSessionDateTime } from "../EventViewer/helpers/sessionDateFormatter";
import styles from "./RedirectToExternalEvent.module.scss";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

export const RedirectToExternalEvent = () => {
  const { eventId } = useParams();
  const [searchParams] = useSearchParams();

  const { t } = useTranslation("events");

  const dispatch = useDispatch();
  const status = useSelector(statusSelector);
  const event = useSelector(eventSelector);
  const hasAccess = useSelector(hasAccessSelector);
  const appointment = useSelector(appointmentSelector);
  const error = useSelector(errorSelector);

  const sessionInfo = useMemo(() => {
    const request = queryStringService.parse<GetEventAppointmentRequest>(searchParams.toString());
    return { ...request, eventId: Number(eventId) };
  }, [eventId, searchParams]);

  const { sessionId, sessionStartDate } = sessionInfo;

  useEffect(() => {
    dispatch(req({ ...sessionInfo }));

    return () => {
      dispatch(reset());
    };
  }, [dispatch, sessionInfo]);

  useEffect(() => {
    if (!appointment || !eventId) return;

    // This handles the case when the event is scheduled to end on the next day.
    if (appointment.startsAt > appointment.endsAt) {
      const minutesInDay = 1440;
      appointment.endsAt += minutesInDay;
    }

    const isEventOver = isTicksBefore(
      formatDate(sessionStartDate, appointment.endsAt, appointment.timeZone),
      appointment.timeZone,
    );
    if (isEventOver) return;

    initRedirect(appointment, sessionStartDate, () =>
      dispatch(
        track({
          sessionId: sessionId,
          sessionStart: sessionStartDate,
        }),
      ),
    );
  }, [appointment, dispatch, eventId, sessionId, sessionStartDate]);

  const description = useMemo(() => {
    if (!appointment) {
      return undefined;
    }

    const timeRange = formatSessionDateTime(
      formatDate(sessionStartDate, appointment?.startsAt),
      formatDate(sessionStartDate, appointment?.endsAt),
    );
    return `This event session has not yet started. Check back on ${timeRange} (${appointment?.timeZone})`;
  }, [appointment, sessionStartDate]);

  return (
    <FixedHeightAppPageWrapper>
      <div className={styles.root}>
        <FetchingLayout status={status} errorMessage={error}>
          <BackTitle to={`/discover`} title={event?.title ?? ""} />
          <Divider fitted />
          <div className={styles.content}>
            <div className={styles.eventInfo}>
              {hasAccess ? (
                <>
                  {event && (
                    <>
                      <EventInfo {...event} />
                      <Divider fitted />
                    </>
                  )}
                  <EmptyState
                    icon={<Calendar className={styles.emptyStateIcon} />}
                    title={t("common.notStarted")}
                    description={description}
                    className={styles.notStarted}
                    fullWidth
                  />
                </>
              ) : (
                <HttpErrorView code={ErrorType.Forbidden} back />
              )}
            </div>
          </div>
        </FetchingLayout>
      </div>
    </FixedHeightAppPageWrapper>
  );
};

function initRedirect(appointment: EventAppointment, startDate: string, onRedirect: () => void) {
  const diff = ticksToMoment(formatDate(startDate, appointment.startsAt, appointment.timeZone), appointment.timeZone);

  setTimeout(onRedirect, diff - REDIRECT_TIMEOUT);
}

function formatDate(date: string, minutes: number, tz = "UTC"): string {
  return dayjs(dayjs(date).startOf("d").format("LL")).startOf("day").tz(tz, true).add(minutes, "m").toString();
}
