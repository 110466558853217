import classNames from "classnames/bind";
import { StrictMode, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { OnClickOutside } from "../../../Account/components/OnClickOutside/OnClickOutside";
import { usePageChange } from "../../hooks/usePageChange";
import { AppNavigation, NavigationLink } from "../AppNavigation/AppNavigation";
import { BreakpointContext } from "../context/BreakpointContext";
import styles from "./AppHeader.module.scss";
import mLogo from "./bsi-logo-sx.svg";
import logo from "./logo-expanded.svg";

const cx = classNames.bind(styles);
export interface Props {
  search: React.ReactNode;
  account: React.ReactNode;
  links: NavigationLink[];
}

export const AppHeader: React.FC<Props> = ({ search, account, links }) => {
  const { t } = useTranslation("application");
  const [opened, setOpened] = useState(false);
  const { isLargeScreen, isMobileScreen, isTabletScreen } = useContext(BreakpointContext);
  usePageChange(() => setOpened(false));
  const toggle = () => {
    setOpened(s => !s);
  };

  useEffect(() => {
    setOpened(() => !isMobileScreen);
  }, [isMobileScreen]);

  const classNameMobileMenu = cx(styles.linksMobile, {
    [styles.menuVisible]: isMobileScreen && opened,
  });

  const smallLogo = <img src={mLogo} className={styles.smallLogo} alt="brainstorm small logo" />;

  const handleSetOpened = useCallback(() => {
    setOpened(false);
  }, []);

  return (
    <StrictMode>
      <header className={styles.root}>
        <div className={styles.headerWrapper}>
          {isLargeScreen && (
            <Link to={links[0].to} className={styles.logoLink}>
              <img src={logo} className={styles.logo} alt="brainstorm full logo" />
            </Link>
          )}
          {isTabletScreen && (
            <Link to={links[0].to} className={styles.logoLink}>
              {smallLogo}
            </Link>
          )}
          {isMobileScreen && (
            <OnClickOutside handler={handleSetOpened}>
              <button className={styles.menuToggler} onClick={toggle} aria-label={t("mainNavigation.openAlt")}>
                {smallLogo}
                <Icon name={opened ? "angle up" : "angle down"} />
              </button>
              <div className={classNameMobileMenu}>
                <AppNavigation isMobileView={true} links={links} />
              </div>
            </OnClickOutside>
          )}
          <div className={styles.search}>{search}</div>
          {!isMobileScreen && (
            <div className={styles.links}>{<AppNavigation isMobileView={false} links={links} />}</div>
          )}
          <div className={styles.account}>{account}</div>
        </div>
      </header>
    </StrictMode>
  );
};
