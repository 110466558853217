import { useCallback, useMemo, useState } from "react";
import { Filter, FilterSlice, FilterType } from "../../types";

const mapFilter = (filter: Filter, from?: any) => {
  if (filter.type === FilterType.CheckboxArray) {
    return filter.items.map(c => from?.includes(c.value));
  }

  return undefined;
};

const init = (
  filters: Filter[],
  initial: DeepPartial<FilterSlice> | undefined = undefined,
  map: { [sectionKey: string]: any } = {},
) => {
  filters.forEach(filter => {
    const items = filter.items;
    const params: { [name: string]: any } = {};

    const values = initial?.[filter.key];
    params[filter.key] = values;

    map[filter.key] = mapFilter(filter, initial?.[filter.key]);
    items.forEach(x => {
      x.subfilter && init([x.subfilter], initial, map);
    });
  });

  return map;
};

const useFiltersController = (
  filters: Filter[],
  initial: DeepPartial<FilterSlice> | undefined,
  onUpdate: (key: Filter, newValue: any) => void,
  onReset: (key: Filter) => void,
  onResetAll: () => void,
) => {
  const [show, setShow] = useState(false);

  const [filterValues, setFilterValues] = useState<{ [sectionKey: string]: any }>(init(filters, initial));

  const isChecked = useCallback(
    (categoryName: string, idx: number) => !!filterValues[categoryName]?.[idx],
    [filterValues],
  );

  const filtersCount = useMemo(() => {
    return Object.values(filterValues)
      .flat()
      .filter(x => !!x).length;
  }, [filterValues]);
  const hasAny = filtersCount > 0;

  const update = useCallback(
    (filter: Filter, newValue: any) => {
      onUpdate(filter, newValue);

      setFilterValues(prevChecked => {
        return { ...prevChecked, [filter.key]: newValue };
      });
    },
    [onUpdate],
  );

  const reset = useCallback(
    (filter: Filter) => {
      onReset(filter);

      setFilterValues(prevChecked => {
        return { ...prevChecked, [filter.key]: mapFilter(filter) };
      });
    },
    [setFilterValues, onReset],
  );

  const resetAll = useCallback(() => {
    if (!hasAny) {
      return;
    }

    onResetAll();

    setFilterValues(init(filters));
  }, [filters, hasAny, onResetAll]);

  const res = useMemo(
    () => ({
      filters,
      filterValues,
      show,
      setShow,
      filtersCount,
      isChecked,
      reset,
      update,
      resetAll,
    }),
    [filters, filterValues, filtersCount, isChecked, reset, resetAll, show, update],
  );

  return res;
};

export default useFiltersController;
