import { EntityType } from "../../common/types";
import { FilterNames, FilterType, FilterValue, PriorityLevel, asFilter, asFilters } from "../types";

export const getPriorityFilters = (disabled: boolean) =>
  asFilter({
    key: "priority",
    disabled: disabled,
    type: FilterType.CheckboxArray,
    items: [
      {
        id: "required",
        value: PriorityLevel.Required,
      },
      {
        id: "important",
        value: PriorityLevel.Important,
      },
      {
        id: "normal",
        value: PriorityLevel.Normal,
      },
    ],
  });

export const getFilters = (priorityFilters: ReturnType<typeof getPriorityFilters>) =>
  asFilters([
    {
      key: "entityType",
      type: FilterType.CheckboxArray,
      items: [
        {
          id: "flows",
          value: EntityType.Flow,
          subfilter: asFilter(priorityFilters),
        },
        {
          id: "events",
          value: EntityType.Event,
        },
        {
          id: "videos",
          value: EntityType.Video,
        },
        {
          id: "surveys",
          value: EntityType.Survey,
        },
        {
          id: "assessments",
          value: EntityType.Assessment,
        },
        {
          id: "PDFs",
          value: EntityType.Pdf,
        },
      ],
    },
  ]);

export type DiscoveryFilterName = FilterNames<ReturnType<typeof getFilters>>;
export type DiscoveryFilters = { filters: { [key in DiscoveryFilterName]?: FilterValue[] } };
