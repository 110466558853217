import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../Application/store/store";
import { RStatus } from "../../../Application/types";
import { FilterSlice, DiscoveryItem, SortType, Filter, FilterType } from "../../types";

export interface Search {
  filters: FilterSlice;
  sortType: SortType;
  term: string | undefined;
}

export interface SearchResultsState {
  searchInitialized: boolean;
  search: Search;
  status: RStatus;
  items: DiscoveryItem[];
  errorMessage: string | undefined;
  top: number;
  activePage: number;
  reachedEnd: boolean;
}

export const initialState: SearchResultsState = {
  search: {
    term: undefined,
    filters: {},
    sortType: SortType.Relevant,
  },
  searchInitialized: false,
  items: [],
  status: RStatus.Idle,

  errorMessage: undefined,
  top: 50,
  activePage: 1,
  reachedEnd: false,
};

const searchResultsSlice = createSlice({
  name: "searchResults",
  initialState: initialState,
  reducers: {
    req(state) {
      state.reachedEnd = false;
      state.items = [];
      state.status = RStatus.Pending;
    },
    got(state, action: PayloadAction<{ items: SearchResultsState["items"] }>) {
      return { ...state, status: RStatus.Got, items: action.payload.items };
    },
    fetchMore(state) {
      if (state.reachedEnd) return state;
      return {
        ...state,
        status: RStatus.Pending,
        activePage: state.activePage + 1,
      };
    },
    updateStatus(state, action: PayloadAction<{ status: RStatus }>) {
      return {
        ...state,
        status: action.payload.status,
      };
    },
    updateSort(state, action: PayloadAction<{ value: SortType }>) {
      state.search.sortType = action.payload.value;
      state.activePage = initialState.activePage;
    },
    updateTerm(state, action: PayloadAction<string>) {
      state.search.term = action.payload;
      state.activePage = initialState.activePage;
    },
    resetFilter(state, action: PayloadAction<Filter>) {
      delete state.search.filters[action.payload.key];
      state.status = RStatus.Pending;
      state.reachedEnd = false;
      state.activePage = initialState.activePage;
    },
    resetFilters(state) {
      state.search.filters = {};
      state.status = RStatus.Pending;
      state.reachedEnd = false;
      state.activePage = initialState.activePage;
    },
    updateFilters(state, action: PayloadAction<{ filter: Filter; newValue?: any }>) {
      const { filter, newValue } = action.payload;

      const mapFilters = () => {
        if (filter.type === FilterType.CheckboxArray) {
          return filter.items.filter((_, i) => !!newValue[i]).map(x => x.value);
        }
        return [];
      };

      state.search.filters[filter.key] = mapFilters();
      state.status = RStatus.Pending;
      state.reachedEnd = false;
      state.activePage = initialState.activePage;
    },
    clearFilters(state) {
      state.search = initialState.search;
      state.status = RStatus.Pending;
      state.reachedEnd = false;
      state.activePage = initialState.activePage;
    },
    outOfResults(state) {
      state.reachedEnd = true;
    },
    err(
      state,
      action: PayloadAction<{
        errorMessage: SearchResultsState["errorMessage"];
      }>,
    ) {
      return {
        ...state,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    initSearch(state, action: PayloadAction<{ search: DeepPartial<Search> }>) {
      state.search = Object.assign({}, state.search, action.payload.search); // NOSONAR
      state.searchInitialized = true;
    },
    reset() {
      return initialState;
    },
  },
});

// actions
export const {
  req,
  got,
  fetchMore,
  updateStatus,
  updateSort,
  updateFilters,
  clearFilters,
  outOfResults,
  err,
  reset,
  initSearch,
  updateTerm,
  resetFilter,
  resetFilters,
} = searchResultsSlice.actions;

// TO-DO move selectors to a separate file
// selectors
export const getSearchResults = (state: RootState) => state.discover.searchResults;
export const selectSearch = (state: RootState) => state.discover.searchResults.search;
export const selectSearchFilters = (state: RootState) => state.discover.searchResults.search.filters;
export const selectSearchTerm = (state: RootState) => state.discover.searchResults.search.term;

export const selectSearchResults = (state: RootState) => state.discover.searchResults.items;
export const selectSearchSortType = (state: RootState) => state.discover.searchResults.search.sortType;
export const selectSearchResultsStatus = (state: RootState) => state.discover.searchResults.status;
export const selectSearchResultsError = (state: RootState) => state.discover.searchResults.errorMessage;
export const selectSearchResultsActivePage = (state: RootState) => state.discover.searchResults.activePage;
export const selectSearchResultsReachedEnd = (state: RootState) => state.discover.searchResults.reachedEnd;

// reducers
export default searchResultsSlice.reducer;
