import classNames from "classnames/bind";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import { InvisibleButton } from "../../../Application/layout";
import { Filter, FilterItem } from "../../types";
import { Checkbox } from "../../../Application/layout/Checkbox/Checkbox";

import styles from "./FilterSection.module.scss";

const MIN_CHECKBOXES_TO_SHOW = 3;

const testLaterBoxes = (length: number, values: any) => {
  for (let i = MIN_CHECKBOXES_TO_SHOW; i < length; i++) {
    if (values[i]) return true;
  }
  return false;
};

const cx = classNames.bind(styles);

interface Props {
  filter: Filter;
  valueProvider: (key: string) => any;
  update: (filter: Filter, newValue: any) => void;
  reset: (filter: Filter) => void;
  className?: string;
  expandable?: boolean;
}

interface CheckboxProps {
  idx: number;
  checked: boolean;
  headerName: string;
  cat: FilterItem;
  check: (idx: number) => void;
  renderSubfilter: (filter: Filter) => JSX.Element;
  disabled?: boolean;
}

const CheckboxSectionComponent: React.FC<CheckboxProps> = ({
  idx,
  checked,
  headerName,
  cat,
  check,
  renderSubfilter,
  disabled,
}) => {
  const { t, i18n } = useTranslation(["discover"]);

  const getFilterName = (categoryName: string, itemId: string) => {
    if (i18n.exists(`discover:filters.${categoryName}.${itemId}`)) {
      return t(`filters.${categoryName}.${itemId}`);
    }
    return itemId;
  };

  return (
    <div>
      <Checkbox
        label={
          <span className={cx({ [styles.checkboxChecked]: !disabled && checked })}>
            {getFilterName(headerName, cat.id)}
          </span>
        }
        aria-label={getFilterName(headerName, cat.id)}
        checked={!disabled && checked}
        name={`filters.${headerName}.${cat.id}`}
        onChange={() => check(idx)}
        disabled={disabled}
      />
      {!!cat.subfilter && renderSubfilter(cat.subfilter)}
    </div>
  );
};

const CheckboxSection = memo(CheckboxSectionComponent);

// should be converted into component factory in case any new filter type is added
export const FilterSection: React.FC<Props> = ({
  filter,
  valueProvider,
  update,
  reset,
  className,
  expandable = true,
}) => {
  const { key, items, disabled } = filter;
  const values = valueProvider(key);

  const { t, i18n } = useTranslation(["discover"]);
  const [expanded, setExpanded] = useState(true);

  const [showMore, setShowMore] = useState(
    // Are any of the boxes in the "MORE" section checked?
    // If so, start with it open
    items.length > MIN_CHECKBOXES_TO_SHOW - 1 && testLaterBoxes(items.length, values),
  );

  useEffect(() => {
    if (disabled) {
      reset(filter);
    }
  }, [disabled, filter, reset]);

  const expandSection = useCallback(() => {
    setShowMore(prev => !prev);
  }, []);

  const filterCategoryName = i18n.exists(`discover:filters.${key}.header`) ? t(`filters.${key}.header`) : key;

  const checkItem = (idx: number) => {
    const newValues = [...values];
    newValues[idx] = !newValues[idx];
    update(filter, newValues);
  };

  return (
    <div className={cx(className, { [styles.disabled]: !!disabled })}>
      {expandable ? (
        <button
          className={cx(styles.sectionExpandButton, {
            [styles.titleContainerOpened]: expanded,
            [styles.titleContainerClosed]: !expanded,
          })}
          aria-label={filterCategoryName}
          onClick={() => setExpanded(prev => !prev)}
        >
          <span className={styles.filterSectionName}>{filterCategoryName.toLocaleUpperCase()}</span>
          <span className={styles.iconContainer}>
            <Icon className={styles.mutedIcon} name={expanded ? "angle up" : "angle down"} fitted />
          </span>
        </button>
      ) : (
        <span className={cx(styles.filterSectionName, styles.titleContainerOpened)} aria-label={filterCategoryName}>
          {filterCategoryName.toLocaleUpperCase()}
        </span>
      )}
      {expanded && (
        <>
          {items.map((cat, idx) => (
            <div
              key={`Filter categories ${cat.id}`}
              className={cx(styles.checkboxContainer, {
                [styles.hidden]: idx > MIN_CHECKBOXES_TO_SHOW - 1 && !showMore,
              })}
            >
              <CheckboxSection
                idx={idx}
                headerName={key}
                cat={cat}
                check={checkItem}
                checked={values[idx]}
                disabled={disabled}
                renderSubfilter={subfilter => (
                  <FilterSection
                    className={cx(styles.subfilter)}
                    filter={subfilter}
                    update={update}
                    valueProvider={valueProvider}
                    reset={reset}
                    expandable={false}
                  />
                )}
              />
            </div>
          ))}
          {items.length > 3 && (
            <InvisibleButton onClick={expandSection}>
              <span className={styles.moreButton}>{showMore ? t("common.less") : t("common.more")}</span>
            </InvisibleButton>
          )}
        </>
      )}
    </div>
  );
};

export default memo(FilterSection);
