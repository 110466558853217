import { trackUrl } from "features/Auth/services/urlTrackingService";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { ORIGIN_WEB, UTM_CAMPAIGN, UTM_MEDIUM, UTM_SOURCE, UTM_TYPE } from "./constants";

export type UrlTrackingParams = {
  campaign: string;
  medium: string;
  source: string;
  type: string;
  anyTrackingParamExist: boolean;
};

export const TrackSource = ({ children }: React.PropsWithChildren) => {
  const location = useLocation();
  const setSearchParams = useSearchParams()[1];

  useEffect(() => {
    try {
      const trackingParams = getTrackingParamsFromUrl(location.search);
      if (trackingParams.anyTrackingParamExist) {
        const params = deleteTrackingParamsFromUrl(location.search);
        setSearchParams(params.toString(), { replace: true });
        const url = new URL(window.location.href);
        url.search = params.toString();
        trackUrl({
          url: url.toString(),
          origin: ORIGIN_WEB,
          campaign: trackingParams.campaign,
          medium: trackingParams.medium,
          source: trackingParams.source,
          type: trackingParams.type,
        });
      }
    } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export const getTrackingParamsFromUrl = (searchParams: string): UrlTrackingParams => {
  const params = new URLSearchParams(searchParams);

  const campaign = params.get(UTM_CAMPAIGN);
  const medium = params.get(UTM_MEDIUM);
  const source = params.get(UTM_SOURCE);
  const type = params.get(UTM_TYPE);

  const anyParamExist = !isEmpty(campaign) || !isEmpty(medium) || !isEmpty(source);

  return {
    campaign: campaign ?? "",
    medium: medium ?? "",
    source: source ?? "",
    type: type ?? "",
    anyTrackingParamExist: anyParamExist,
  };
};

export const deleteTrackingParamsFromUrl = (searchParams: string): URLSearchParams => {
  const params = new URLSearchParams(searchParams);

  params.delete(UTM_CAMPAIGN);
  params.delete(UTM_MEDIUM);
  params.delete(UTM_SOURCE);
  params.delete(UTM_TYPE);

  return params;
};
