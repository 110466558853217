import classNames from "classnames/bind";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { FixedHeightAppPageWrapper } from "../../../Application/layout";
import { ContentList } from "../ContentList/ContentList";

import {
  Search,
  fetchMore,
  getSearchResults,
  initSearch,
  reset,
  resetFilter,
  resetFilters,
  selectSearch,
  selectSearchResults,
  selectSearchResultsError,
  selectSearchResultsReachedEnd,
  selectSearchResultsStatus,
  updateFilters,
  updateSort,
} from "../../redux/searchResults/searchResultsSlice";
import { Filter, SortType } from "../../types";
import { useTranslation } from "react-i18next";
import { DropdownItemProps } from "semantic-ui-react";
import { SendPageView } from "../../../Application/services/realTimeNotification/googleAnalytics";
import { getParamValue } from "../../../Application/utils/routing";
import { SEARCH_TERM } from "../../constants";
import { useQueryObserver } from "../../hooks/useQueryObserver";
import { Header } from "./Header/Header";
import useFiltersController from "../Filters/useFiltersController";
import { useSortController } from "../Sorting/useSortCotroller";
import FiltersBar from "../Filters/FiltersBar";
import { DiscoveryFilterName, getFilters, getPriorityFilters } from "../../config/filters";
import FilteringControlls from "./Header/FilteringControlls";
import { EntityType } from "../../../common/types";
import CustomDropdown from "../../../Flows/components/CustomDropdown/CustomDropdown";

import styles from "./DiscoverPage.module.scss";

const cx = classNames.bind(styles);

const isPrioritiesDisabled = (search: Search | DeepPartial<Search>) => {
  const type: DiscoveryFilterName = "entityType";
  const selected = search?.filters?.[type];
  return !!search.term || selected === undefined || selected.length !== 1 || selected[0] !== EntityType.Flow;
};

export const DiscoverPage: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation(["discover"]);

  const onSortChange = useCallback(
    (newValue: any) => {
      dispatch(updateSort({ value: newValue }));
    },
    [dispatch],
  );
  const { search, searchInitialized } = useSelector(getSearchResults);

  const [initialValues] = useQueryObserver(useSelector(selectSearch));

  const prevTerm = useRef(initialValues.term);

  const isPriorityDisabled = useMemo(() => {
    const value = searchInitialized ? search : initialValues;
    return isPrioritiesDisabled(value);
  }, [search, searchInitialized, initialValues]);

  const filters = useMemo(() => getFilters(getPriorityFilters(isPriorityDisabled)), [isPriorityDisabled]);

  useEffect(() => {
    dispatch(initSearch({ search: initialValues }));
    return () => {
      dispatch(reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdate = useCallback(
    (filter: Filter, newValue: any) => {
      dispatch(
        updateFilters({
          filter,
          newValue,
        }),
      );
    },
    [dispatch],
  );

  const onReset = useCallback(
    (filter: Filter) => {
      dispatch(resetFilter(filter));
    },
    [dispatch],
  );

  const onResetAll = useCallback(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  const filtersBar = useFiltersController(filters, initialValues.filters, onUpdate, onReset, onResetAll);
  const { resetAll: resetAllFilters } = filtersBar;

  const { showSort, setShowSort, toggleSort, isMobile } = useSortController();

  const { term } = search;
  useEffect(() => {
    if (prevTerm.current !== term) {
      prevTerm.current = term;
      resetAllFilters();
    }
  }, [resetAllFilters, term]);

  const dropdownOptions: DropdownItemProps[] = useMemo(
    () => [
      {
        key: "mostRelevant",
        text: t("sort.mostRelevant"),
        value: SortType.Relevant,
      },
    ],
    [t],
  );

  // Changing page title and updating google analytics
  useEffect(() => {
    // Do we have a search string (in other words, on the search page?)
    let query = "";
    if (location.search) {
      query = getParamValue(location.search, SEARCH_TERM);
    }
    let newTitle = query ? `${query} - Search` : "Discover";
    if (document.title === newTitle) return;

    document.title = newTitle;
    SendPageView({
      title: query ? "Search" : "Discover",
    });
  }, [location.search]);

  const handleCloseDropdown = useCallback(
    (e: MouseEvent | TouchEvent) => {
      const target = e.target as HTMLInputElement;
      if (!target.className.includes("sort amount down icon")) setShowSort(false);
    },
    [setShowSort],
  );

  return (
    <FixedHeightAppPageWrapper reactStrictMode={false}>
      <div className={styles.flexGrid}>
        <Header
          controls={
            <FilteringControlls
              filtersCount={filtersBar.filtersCount}
              setShowFilters={filtersBar.setShow}
              setSortOpen={setShowSort}
              showFilters={filtersBar.show}
              sortOpen={showSort}
            />
          }
        />
        <div className={styles.mainContent}>
          <div className={styles.filterBarContainer}>
            <FiltersBar {...filtersBar} />
          </div>
          <div className={cx(styles.flowList, styles.leftPadding)}>
            <ContentList
              closeDropdown={handleCloseDropdown}
              dropdown={
                <CustomDropdown
                  options={dropdownOptions}
                  isVisible={showSort}
                  toggleVisible={toggleSort}
                  defaultValue={initialValues.sortType}
                  onChange={onSortChange}
                  isMobile={isMobile}
                />
              }
              selectResult={selectSearchResults}
              selectStatus={selectSearchResultsStatus}
              selectError={selectSearchResultsError}
              selectReachedEnd={selectSearchResultsReachedEnd}
              fetchMore={fetchMore}
            />
          </div>
        </div>
      </div>
    </FixedHeightAppPageWrapper>
  );
};
