import { all, fork } from "redux-saga/effects";
import { mainContactWatcherSaga } from "../../Account/redux/mainContact/mainContactSagas";
import { accountWatcherSaga } from "../../Account/redux/account/accountSagas";
import { myDataWatcherSaga } from "../../Account/redux/myData/myDataSagas";
import { profileWatcherSaga } from "../../Account/redux/profile/profileSagas";
import { assessmentWatcherSaga } from "../../Assets/redux/assessment/assessmentSagas";
import { assetWatcherSaga } from "../../Assets/redux/asset/assetSagas";
import { pdfWatcherSaga } from "../../Assets/redux/pdf/pdfSagas";
import { surveyWatcherSaga } from "../../Assets/redux/survey/surveySagas";
import { publishersWatcherSaga } from "../../Discover/redux/publishers/publishersSagas";
import { searchResultsWatcherSaga } from "../../Discover/redux/searchResults/searchResultsSagas";
import { eventWatcherSaga } from "../../Events/redux/event/eventSagas";
import { eventRedirectWatcherSaga } from "../../Events/redux/event/redirectSaga";
import { eventSessionsWatcherSaga } from "../../Events/redux/sessions/sessionsSagas";
import { flowWatcherSaga } from "../../Flows/redux/flow/flowSagas";
import { groupWatcherSaga } from "../../Groups/redux/group/groupSagas";
import { groupsWatcherSaga } from "../../Groups/redux/groups/groupsSagas";
import { homePageContentWatcherSaga } from "../../Home/redux/homePageContent/homePageContentSaga";
import { homeUpcomingEventsWatcher } from "features/Home/redux/homePageContent/homeUpcomingEventsSaga";

export default function* rootSaga() {
  yield all([
    // Leave this comment for hygen inserting feature
    fork(mainContactWatcherSaga),
    fork(groupWatcherSaga),
    fork(profileWatcherSaga),
    fork(publishersWatcherSaga),
    fork(flowWatcherSaga),
    fork(accountWatcherSaga),
    fork(searchResultsWatcherSaga),
    fork(assetWatcherSaga),
    fork(assessmentWatcherSaga),
    fork(surveyWatcherSaga),
    fork(pdfWatcherSaga),
    fork(groupsWatcherSaga),
    fork(eventWatcherSaga),
    fork(homePageContentWatcherSaga),
    fork(eventRedirectWatcherSaga),
    fork(eventSessionsWatcherSaga),
    fork(myDataWatcherSaga),
    fork(homeUpcomingEventsWatcher),
  ]);
}
